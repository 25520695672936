<template>
    <BT-Blade-Item
        bladeName="customer"
        :bladesData="bladesData"
        :canEdit="false"
        :canSave="false"
        :loadingMsg="loadingMsg"
        navigation="customers"
        :otherUsedHeight="48"
        :refreshToggle="refreshToggle"
        title="Customer">
        <template v-slot="{ data, item, save }">
            <v-row v-if="data.isNew" no-gutters>
                <v-col cols="12" sm="6" key="1">
                    <BT-Field-String
                        v-model="item.companyName"
                        label="Company Name"
                        isEditing
                        :rules="requiredRules()" />
                        
                    <BT-Field-String
                        v-model="item.email"
                        label="Email Address"
                        isEditing
                        :rules="requiredRules()" />

                    <BT-Field-String
                        v-model="item.phoneNumber"
                        label="Phone Number"
                        isEditing />

                    <v-divider />

                    <BT-Field-Select
                        v-model="item.defaultTemplateID"
                        :isEditing="item.companyName != null && item.email != null"
                        itemText="templateName"
                        label="Copy Template"
                        navigation="customer-templates" />
                </v-col>
                <v-slide-x-transition>
                <v-col v-if="item.companyName != null && item.email != null" cols="12" sm="6" key="2">
                    <BT-Field-String
                        label="Address Line One"
                        v-model="item.addressLineOne"
                        isEditing />
                    <BT-Field-String
                        label="Street Number"
                        v-model="item.streetNumber"
                        isEditing />
                    <BT-Field-String
                        label="Street Name"
                        v-model="item.streetName"
                        isEditing />
                    <BT-Field-String
                        label="Suburb"
                        v-model="item.suburb"
                        isEditing />
                    <BT-Field-String
                        label="State"
                        v-model="item.state"
                        isEditing />
                    <BT-Field-String
                        label="Postcode"
                        v-model="item.postcode"
                        isEditing />
                </v-col>
                </v-slide-x-transition>
                <v-col cols="12" key="3">
                    <v-divider class="my-2" />
                </v-col>
                <v-col cols="12" sm="6" key="4">
                    <v-list-item>
                        <v-list-item-content>
                            <v-btn class="primary" :disabled="item.suburb == null && item.state == null && item.postcode == null" @click="validate(item)">
                                Find Address Coordinates
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>

                    <BT-Field-String
                        label="Latitude"
                        v-model="item.lat"
                        isEditing />

                    <BT-Field-String
                        label="Longitude"
                        v-model="item.lng"
                        isEditing />
                </v-col>
                <v-slide-x-transition>
                <v-col v-if="item.lat != null && item.lng != null" cols="12" sm="6" key="5">
                        <GmapMap
                            ref="locMap"
                            :center="centerPosition"
                            :zoom="7"
                            style="width: 100%; height: 250px;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">
                            <gmap-marker v-if="position != null" :position="position" />
                        </GmapMap>
                    </v-col>
                </v-slide-x-transition>
                <v-slide-x-transition>
                    <v-col v-if="item.lat != null && item.lng != null" cols="12" key="6">
                        <v-btn class="primary ma-4" @click="create(item)">
                            Create
                        </v-btn>
                    </v-col>
                </v-slide-x-transition>
                <BT-Snack v-model="msg" key="7" />
            </v-row>
            <v-container v-else>
                <BT-Entity
                    v-if="item.buyer.isManagedByAnotherAccount"
                    ignoreID
                    navigation="managed-company-details"
                    :proxyID="item.buyerID"
                    single>
                    <template v-slot="{ item, data, save }">
                        <v-row>
                            <v-col cols="12" sm="6" class="text-center">
                                <BT-Image-Edit
                                    v-if="data.isEditing"
                                    :canEdit="data.isEditing"
                                    :height="150"
                                    :id="item.id"
                                    justify-center align-center
                                    navigation="customer-image"
                                    placeholder="mdi-account-box"
                                    :src="companyLogoURL(item.id) + '?' + cacheData"
                                    @uploaded="cacheData = new Date().getTime()"
                                    :width="150" />
                                <v-img
                                    v-else
                                    height="150"
                                    :src="companyLogoURL(item.id)"
                                    width="150">
                                    <template v-slot:placeholder>
                                        <v-icon size="150" color="primary">mdi-account-box</v-icon>
                                    </template>
                                </v-img>

                            </v-col>
                            <v-col cols="12" sm="6">
                                <BT-Field-String
                                    v-model="item.companyName"
                                    label="Company Name"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />
                                    
                                <BT-Field-String
                                    v-model="item.primaryEmail"
                                    label="Primary Email Address"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />

                                <BT-Field-String
                                    v-model="item.phoneNumber"
                                    label="Phone Number"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-String
                                    v-model="item.website"
                                    label="Website"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-Select
                                    label="Default Timezone"
                                    v-model="item.defaultTimeZone"
                                    :items="timeZones"
                                    :isEditing="data.isEditing"
                                    :rules="requiredRules()" />

                            </v-col>
                        </v-row>
                        <v-slide-y-transition hide-on-leave>
                            <v-row v-if="data.isChanged" class="mt-0">
                                <v-btn v-if="data.isChanged" @click="save" block class="primary mt-5">
                                    <v-icon left>mdi-content-save</v-icon>Save
                                </v-btn>
                            </v-row>
                        </v-slide-y-transition>
                    </template>
                </BT-Entity>
                <v-row v-else>
                    <v-col cols="12" sm="6" class="text-center">
                        <v-img
                            class="mx-auto"
                            height="150"
                            :src="companyLogoURL(item.buyerID)"
                            width="150">
                            <template v-slot:placeholder>
                                <v-icon size="150" color="primary">mdi-account-box</v-icon>
                            </template>
                        </v-img>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.buyer.companyName"
                            label="Company Name"
                            :rules="requiredRules()" />

                        <BT-Field-String
                            v-model="item.buyer.primaryEmail"
                            label="Email Address" />

                    </v-col>
                </v-row>

                <v-divider class="my-1" />

                <v-row v-if="item != null">
                    <v-col cols="1">
                        <v-list max-width="56">
                            <BT-Menu
                                v-if="item.buyer.isManagedByAnotherAccount"
                                icon="mdi-map-marker-multiple"
                                :item="item"
                                title="Locations">
                                <template>
                                    <BT-Blade-Items
                                        addBladeName="location"
                                        canAdd
                                        hideBackButton
                                        hideHeader
                                        navigation="locations"
                                        :proxyID="item.buyerID"
                                        showList>
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item | toFamiliarLocationLine }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Items>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-currency-usd"
                                :item="item"
                                navigation="customers"
                                title="Finance">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Select
                                        v-model="item.priceTierID"
                                        itemText="priceTierName"
                                        label="Price Tier"
                                        navigation="supplier-price-tiers"
                                        :isEditing="data.isEditing" />
                                    
                                    <BT-Field-Select
                                        v-model="item.paymentTermsID"
                                        itemText="paymentTermsName"
                                        label="Payment Terms"
                                        navigation="supplier-payment-terms"
                                        :isEditing="data.isEditing" />

                                    <v-btn 
                                        class="ma-1"
                                        :class="item.isSuspended ? 'success' : 'error'"
                                        block
                                        @click="item.isSuspended = !item.isSuspended">
                                        {{ item.isSuspended ? 'Unsuspend Account' : 'Suspend Account' }}
                                    </v-btn>
                                    
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-calendar-multiple-check"
                                :isTrue="item => item.orderingArrangement == 'Slotted'"
                                :item="item"
                                navigation="customers"
                                title="Order Time Restrictions">
                                <template v-slot="{ data, item }">

                                    <v-slide-x-transition group hide-on-leave>
                                        <BT-Field-Toggle-Trigger
                                            v-model="item.orderingArrangement"
                                            :isEditing="data.isEditing"
                                            label="Ordering Arrangement"
                                            :toggleOptions="[{ text: 'Open', value: 'Open' }, { text: 'Slotted', value: 'Slotted' }]"
                                            key="8.1" />
                                            
                                        <!-- <BT-Field-Select
                                            v-if="item.orderingArrangement == 'Slotted'"
                                            navigation="order-slot-groups"
                                            v-model="item.orderSlotGroupID"
                                            :isEditing="data.isEditing"
                                            label="Order Slots"
                                            item-text="groupName"
                                            item-value="id"
                                            key="9" /> -->
                                        
                                        <v-row key="9.1">
                                            <v-col cols="6">
                                                <BT-Square-Number
                                                    v-if="item.orderingArrangement == 'Slotted'"
                                                    v-model.number="item.scheduleOrderLimit"
                                                    :isEditing="data.isEditing"
                                                    label="Max Future Orders"
                                                    type="number"
                                                    width="180" />
                                            </v-col>
                                            <v-col cols="6">
                                                <BT-Square-Number
                                                    v-if="item.orderingArrangement == 'Slotted'"
                                                    v-model.number="item.scheduleDayLimit"
                                                    :isEditing="data.isEditing"
                                                    label="Max Future Days"
                                                    type="number"
                                                    width="180" />
                                            </v-col>
                                        </v-row>

                                        <BT-Field-Checkbox
                                            v-if="item.orderingArrangement == 'Open' || item.orderingArrangement == null"
                                            v-model="item.requireLeadTime"
                                            :isEditing="data.isEditing"
                                            label="Order Lead Time"
                                            key="10" />

                                        <BT-Field-String
                                            v-if="(item.orderingArrangement == 'Open' || item.orderingArrangement == null) && item.requireLeadTime"
                                            outlined
                                            label="Order Lead Hours"
                                            v-model="item.orderLeadHours"
                                            :isEditing="data.isEditing"
                                            key="11" />
                                            
                                        <BT-Field-Checkbox
                                            v-if="item.orderingArrangement == 'Open' || item.orderingArrangement == null"
                                            v-model="item.isAvailableWeekends"
                                            :isEditing="data.isEditing"
                                            label="Can Deliver On Weekends"
                                            dense
                                            key="12" />

                                        

                                    </v-slide-x-transition>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-ruler-square"
                                :isTrue="item => item.useOrderItemRules"
                                :item="item"
                                navigation="customers"
                                title="Order Customization">
                                <template v-slot="{ data, item }">
                                    <v-slide-y-transition group hide-on-leave>
                                        <BT-Field-Switch
                                            v-model="item.useOrderItemRules"
                                            label="Use Rules"
                                            :isEditing="data.isEditing"
                                            key="1" />

                                        <BT-Field-Select
                                            v-if="item.useOrderItemRules"
                                            v-model="item.orderItemRulesGroupID"
                                            itemText="groupName"
                                            label="Rules"
                                            navigation="ordering-rules-groups"
                                            :isEditing="data.isEditing"
                                            key="2" />

                                        <v-divider class="my-2" key="3" />

                                        <BT-Field-Switch
                                            v-model="item.isCustomerOrderingEnabled"
                                            label="Customer Can Place Orders"
                                            :isEditing="data.isEditing"
                                            key="4" />

                                        <v-divider class="my-2" key="5" />

                                        <BT-Field-Select
                                            v-model="item.productOfferingGroupID"
                                            itemText="groupName"
                                            label="Product Groups"
                                            navigation="product-groups"
                                            :isEditing="data.isEditing"
                                            key="6" />

                                        <BT-Field-Select
                                            v-model="item.automaticLineItemGroupID"
                                            itemText="groupName"
                                            label="Automatic Lines"
                                            navigation="automatic-line-item-groups"
                                            :isEditing="data.isEditing"
                                            key="7" />
                                        
                                        <v-divider class="my-2" key="8" />
                                    </v-slide-y-transition>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-timer"
                                :item="item"
                                navigation="customers"
                                title="Automation">
                                <template v-slot="{ item }">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Approve Purchase Orders</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <BT-Toggle-Trigger-Icon v-model="item.approvalTrigger" />
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Select Fulfiller</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <BT-Toggle-Trigger-Icon v-model="item.fulfillerTrigger" />
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Process Orders</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <BT-Toggle-Trigger-Icon v-model="item.processTrigger" />
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                icon="mdi-cube"
                                :item="item"
                                navigation="customers"
                                title="Stock Settings">
                                <template v-slot="{ data, item }">
                                    <v-slide-x-transition group hide-on-leave>
                                        <BT-Field-Checkbox
                                            v-model="item.isRequirePickup"
                                            :isEditing="data.isEditing"
                                            label="Customer Must Pickup"
                                            key="1" />

                                        <BT-Entity
                                            inline
                                            :itemValue="item.id"
                                            navigation="customers-stock"
                                            single
                                            key="2">
                                            <template v-slot="stockSettings">
                                                <BT-Field-Select
                                                    @change="stockSettings.save"
                                                    navigation="locations"
                                                    v-model="stockSettings.item.defaultLocationID"
                                                    label="Default Dispatch Location"
                                                    :isEditing="stockSettings.data.isEditing"
                                                    item-text="locationName"
                                                    item-value="id"
                                                    key="12" />
                                            </template>
                                        </BT-Entity>

                                    </v-slide-x-transition>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-truck-fast"
                                :item="item"
                                navigation="customers"
                                title="Dispatch Settings">
                                <template v-slot="{ data, item }">
                                    <v-slide-x-transition group hide-on-leave>
                                        <BT-Field-Checkbox
                                            v-model="item.isRequirePickup"
                                            :isEditing="data.isEditing"
                                            label="Customer Must Pickup"
                                            key="1" />

                                        <BT-Entity
                                            inline
                                            :itemValue="item.id"
                                            navigation="customers-stock"
                                            single
                                            key="2">
                                            <template v-slot="stockSettings">
                                                <BT-Field-Select
                                                    @change="stockSettings.save"
                                                    navigation="locations"
                                                    v-model="stockSettings.item.defaultLocationID"
                                                    label="Default Dispatch Location"
                                                    :isEditing="stockSettings.data.isEditing"
                                                    item-text="locationName"
                                                    item-value="id"
                                                    key="12" />
                                            </template>
                                        </BT-Entity>

                                    </v-slide-x-transition>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                v-if="item.buyer.isManagedByAnotherAccount"
                                icon="mdi-bell"
                                :item="item"
                                navigation="customers"
                                title="Notification Settings">
                                <template v-slot="{ item }">
                                    <v-slide-x-transition group hide-on-leave>
                                        <BT-Entity
                                            inline
                                            navigation="customer-settings"
                                            :proxyID="item.buyerID"
                                            single
                                            key="1">
                                            <template v-slot="{ item, save }">
                                                <BT-Tags
                                                    v-model="item.emailAddressesAsCustomer"
                                                    label="Emails For Order Notifications"
                                                    class="my-4"
                                                    @change="save" />
                                            </template>
                                        </BT-Entity>
                                        <BT-Entity
                                            inline
                                            navigation="picking-settings"
                                            :proxyID="item.buyerID"
                                            single
                                            key="2">
                                            <template v-slot="{ item, save }">
                                                <BT-Tags
                                                    v-model="item.emailAddressesAsCustomer"
                                                    label="Emails For Stock Notifications"
                                                    class="my-4"
                                                    @change="save" />
                                            </template>
                                        </BT-Entity>
                                        <BT-Entity
                                            inline
                                            navigation="invoicing-settings"
                                            :proxyID="item.buyerID"
                                            single
                                            key="3">
                                            <template v-slot="{ item, save }">
                                                <BT-Tags
                                                    v-model="item.emailAddressesAsCustomer"
                                                    label="Emails For Invoice Notifications"
                                                    class="my-4"
                                                    @change="save" />
                                            </template>
                                        </BT-Entity>
                                    </v-slide-x-transition>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                icon="mdi-history"
                                :item="item"
                                title="Order History">
                                <template>
                                    <BT-Blade-Items
                                        :canSelect="false"
                                        hideBladeHeader
                                        hideHeader
                                        navigation="ordering-user-activities"
                                        :params="{ buyerID: item.buyerID }"
                                        showList>
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.itemID }} {{ item.notes }} by <span><BT-Entity alternateText="system" inline navigation="users" itemText="userName" :itemValue="item.userID" /></span> on <span>{{ item.createdOn | toLongDateAndTime }}</span></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Items>
                                </template>
                            </BT-Menu>

                        </v-list>
                    </v-col>
                    <v-col cols="11">
                        <v-row class="mt-2 mx-0">
                            <BT-Sidebar-List
                                inline
                                label="Groups"
                                linkGetURL="/get/Links?SupplyAgreementID={id}"
                                linkNavigation="customer-groups"
                                manyText="groupName"
                                manyProp="supplyAgreementGroupID"
                                manyToMany
                                manyNavigation="customer-groups"
                                :searchProps="['groupName']"
                                :singleID="item.id"
                                singleProp="supplyAgreementID"
                                small />

                             <BT-Sidebar-List
                                v-if="item.orderingArrangement == 'Slotted'"
                                inline
                                :itemProperties="['ID','SlotName','NextAttentionDate']"
                                label="Order Slots"
                                linkGetURL="/get/Links?AgreementID={id}"
                                linkNavigation="customers"
                                manyText="slotName"
                                manyProp="orderSlotID"
                                manyToMany
                                manyNavigation="order-slots"
                                :searchProps="['slotName']"
                                :singleID="item.id"
                                singleProp="supplyAgreementID"
                                sortProp="slotName"
                                small />
                         
                            <BT-Sidebar-Items
                                :canSearch="false"
                                inline
                                :itemProperties="['ID','JourneyName']"
                                label="Journeys"
                                navigation="journey-templates"
                                :onPullSuccessAsync="list => pullJourneyPointers(list, item)"
                                :searchProps="['journeyTemplate.journeyName']"
                                small
                                :trackChanges="false">
                                <template v-slot="{ item }">
                                    <v-list-item-action>
                                        <v-row>
                                            <v-btn v-if="item.id == null" small icon @click="savePointer(item)" :loading="item.isLoading">
                                                <v-icon small class="error--text">mdi-close</v-icon>
                                            </v-btn>
                                            <v-btn v-else-if="$BlitzIt.tracker.isChanged(item)" small icon @click="savePointer(item)" :loading="item.isLoading">
                                                <v-icon small>mdi-content-save</v-icon>
                                            </v-btn>
                                            <v-btn v-else small icon @click="deletePointer(item)" :loading="item.isLoading">
                                                <v-icon small class="success--text">mdi-check</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="item.journeyTemplate != null">{{ item.journeyTemplate.journeyName }}</v-list-item-title>
                                        <v-list-item-subtitle v-if="item.location != null">{{ item.location | toLocationLine }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.id != null">
                                            <BT-Chips-Menu
                                                v-model="item.weekdays"
                                                column
                                                isEditing
                                                :items="weekdayOptions()"
                                                multiple
                                                returnCSV
                                                xSmall />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </BT-Sidebar-Items>
   
                            <BT-Sidebar-Items
                                :canSearch="false"
                                inline
                                :itemProperties="['ID','LocationName']"
                                label="Supply From"
                                navigation="locations"
                                :onPullSuccessAsync="list => pullSupplyPointers(list, item)"
                                :searchProps="['location.locationName']"
                                small
                                :trackChanges="false">
                                <template v-slot="{ item }">
                                    <v-list-item-action>
                                        <v-row>
                                            <v-btn v-if="item.id == null" small icon @click="saveSupplyPointer(item)" :loading="item.isLoading">
                                                <v-icon small class="error--text">mdi-close</v-icon>
                                            </v-btn>
                                            <v-btn v-else-if="$BlitzIt.tracker.isChanged(item)" small icon @click="saveSupplyPointer(item)" :loading="item.isLoading">
                                                <v-icon small>mdi-content-save</v-icon>
                                            </v-btn>
                                            <v-btn v-else small icon @click="deleteSupplyPointer(item)" :loading="item.isLoading">
                                                <v-icon small class="success--text">mdi-check</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.destinationLocation != null ? item.destinationLocation : null | toLocationLine }}</v-list-item-title>
                                        <v-list-item-subtitle>Supply From: {{ item.location | toFamiliarLocationLine }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.id != null">
                                            <BT-Chips-Menu
                                                v-model="item.weekdays"
                                                column
                                                isEditing
                                                :items="weekdayOptions()"
                                                multiple
                                                returnCSV
                                                xSmall />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </BT-Sidebar-Items>

                            <BT-Sidebar-Items
                                :canSearch="false"
                                inline
                                label="Custom Routes"
                                navigation="routes"
                                :onPullSuccessAsync="list => pullRoutes(list, item)"
                                :searchProps="['routeName']"
                                small
                                :trackChanges="false">
                                <template v-slot="{ item }">
                                    <v-list-item-action>
                                        <v-row>
                                            <v-btn v-if="item.id == null" small icon @click="saveRoutePointer(item)" :loading="item.isLoading">
                                                <v-icon small class="error--text">mdi-close</v-icon>
                                            </v-btn>
                                            <v-btn v-else-if="$BlitzIt.tracker.isChanged(item)" small icon @click="saveRoutePointer(item)" :loading="item.isLoading">
                                                <v-icon small>mdi-content-save</v-icon>
                                            </v-btn>
                                            <v-btn v-else small icon @click="deleteRoutePointer(item)" :loading="item.isLoading">
                                                <v-icon small class="success--text">mdi-check</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.location | toLocationLine }}</v-list-item-title>
                                        <v-list-item-subtitle>via {{ item.routeName }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.id != null">
                                            <BT-Chips-Menu
                                                v-model="item.weekdays"
                                                column
                                                isEditing
                                                :items="weekdayOptions()"
                                                multiple
                                                returnCSV
                                                xSmall />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </BT-Sidebar-Items>

                            <BT-Sidebar-External-Links
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.id"
                                label="Integrate"
                                navigation="customers"
                                small
                                syncNavigation="customer-syncing" />

                            <BT-Sidebar-Live-Links
                                v-if="item.buyer.isManagedByAnotherAccount"
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.buyerID"
                                label="Go Live"
                                rightIcon="mdi-account-multiple-check"
                                small
                                sidebarLabel="Links" />
                            <div v-else class="mx-2 px-2 subtitle-1">
                                <v-icon left>mdi-account</v-icon>Live
                            </div>
                        </v-row>
                        
                        <v-row>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    actualHeight="190px"
                                    addBladeName="customer-order"
                                    canAdd
                                    canSearchLocal
                                    :getNewBladeData="() => { return { agreementID: item.id } }"
                                    :headers="[
                                        { text: 'CO#', value: 'customerOrderNumber', searchable: true },
                                        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: '1', prefix: 'Due: ' },
                                        { text: 'Status', value: 'status', display: true, subtitle: 2 },
                                        { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency', subtitle: 3 }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="5"
                                    :itemProperties="['ID','CustomerOrderNumber','DueDate','IsInactive','IsConfirmed','AmountTotal']"
                                    navigation="customer-orders"
                                    :params="{ customerIDs: item.buyerID }"
                                    showTable
                                    title="Orders">
                                    <template v-slot:status="{ item }">
                                        <span v-if="item.isInactive">Deleted</span>
                                        <span v-else-if="item.isConfirmed == null">Awaiting Response</span>
                                        <span v-else-if="item.isConfirmed == true">Confirmed</span>
                                        <span v-else>Rejected</span>
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5" />
                        <v-row>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    actualHeight="175"
                                    addBladeName="customer-invoice"
                                    canSearchLocal
                                    :headers="[
                                        { text: 'INV#', value: 'invoiceNumber', searchable: true },
                                        { text: 'Due Date', value: 'dueOn', textFilter: 'toDayMonth', subtitle: '1', prefix: 'Due: ' },
                                        { text: 'Status', value: 'status', display: true, subtitle: 2 },
                                        { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency', subtitle: 3 }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="3"
                                    :itemProperties="['ID','DueOn','InvoiceNumber','IsPaid','AmountPaid','AmountTotal']"
                                    navigation="customer-invoices"
                                    :params="{ customerIDs: item.buyerID }"
                                    showTable
                                    title="Invoices">
                                    <!-- <template v-slot:toolbar-right>
                                        <v-btn class="error--text" @click="suspendAccount(item)">Suspend Account</v-btn>
                                    </template> -->
                                    <template v-slot:status="{ item }">
                                        <span v-if="item.isPaid">Paid</span>
                                        <span v-else-if="item.amountPaid > 0">Partially Paid</span>
                                        <span v-else>Unpaid</span>
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Customer-Blade',
    components: {
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTSidebarLiveLinks: () => import('~components/BT-Sidebar-Live-Links.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        // BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    props: {
        bladesData: null
    },
    data: function() {
        return {
            cacheData: new Date().getTime(),
            geocoder: null,
            loadingMsg: null,
            msg: null,
            panelV: null,
            position: null,
            refreshToggle: false,
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    computed: {
        //google: getGoogleMapsAPI,
        centerPosition() {
            return this.position != null ? this.position : { lat: -38, lng: 144 };
        },
        timeZones() {
            return moment.tz.names();
        }
    },
    methods: {
        repositionMarker(location) {
            if (location.lat != null && location.lng != null) {
                this.position = {
                    lat: location.lat,
                    lng: location.lng
                }
            }
            else {
                this.position = null;
            }
        },
        async create(item) {
            this.loadingMsg = `Creating ${item.companyName}`;

             var data = {
                subscriptionCode: 'CUSTFREE',
                companies: [item]
            };

            try {
                await this.$BlitzIt.api.post('new-customers', data, null, '/PostCustomers');

                this.msg = 'Done';
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deletePointer(pointer) {
            try {
                pointer.isLoading = true;
                await this.$BlitzIt.store.delete('journey-pointers', pointer.id, null);
                pointer.id = null;
                pointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async deleteRoutePointer(routePointer) {
            try {
                routePointer.isLoading = true;
                await this.$BlitzIt.store.delete('route-pointers', routePointer.id, null);
                routePointer.id = null;
                routePointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(routePointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                routePointer.isLoading = false;
            }
        },
        async deleteSupplyPointer(pointer) {
            try {
                pointer.isLoading = true;
                await this.$BlitzIt.store.delete('supply-pointers', pointer.id, null);
                pointer.id = null;
                pointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async savePointer(pointer) {
            try {
                pointer.isLoading = true;
                var res = null;
                if (pointer.id == null) {
                    res = await this.$BlitzIt.store.post('journey-pointers', pointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('journey-pointers', pointer, null);
                }
                
                pointer.id = res.id;

                pointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async saveRoutePointer(routePointer) {
            try {
                routePointer.isLoading = true;
                var res = null;
                if (routePointer.id == null) {
                    res = await this.$BlitzIt.store.post('route-pointers', routePointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('route-pointers', routePointer, null);
                }
                
                routePointer.id = res.id;

                routePointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(routePointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                routePointer.isLoading = false;
            }
            
        },
        async saveSupplyPointer(pointer) {
            try {
                pointer.isLoading = true;
                var res = null;
                if (pointer.id == null) {
                    res = await this.$BlitzIt.store.post('supply-pointers', pointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('supply-pointers', pointer, null);
                }
                
                pointer.id = res.id;

                pointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async pullJourneyPointers(templates, agr) {
            console.log('agr');
            console.log(agr);
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, false, null, '/Locations/Locations');
            var locations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(locations)) {
                var locationIDs = locations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('journey-pointers', { includeDetails: true, locationIDs: locationIDs.toString()}, false, null, null);

                templates.forEach(t => {
                    locations.forEach(l => {
                        var p = pointers.find(p => p.locationID == l.id && p.journeyTemplateID == t.id);
                        if (p == null) {
                            p = {
                                id: null,
                                rowVersion: null,
                                pointerName: null,
                                boundary: null,
                                weekdays: 'Always',
                                journeyTemplateID: t.id,
                                locationID: l.id,
                                location: l
                            };
                        }

                        this.$BlitzIt.tracker.track(p, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { journeyTemplate: t, isLoading: false }
                        });

                        res.push(p);
                    });
                });
            }

            return res;
        },
        async pullRoutes(routes, agr) {
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, false, null, '/Locations/Locations');
            var customerLocations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(customerLocations)) {
                var locationIDs = customerLocations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('route-pointers', { includeDetails: true, locationIDs: locationIDs.toString()}, false, null, null);

                routes.forEach(route => {
                    customerLocations.forEach(custLocation => {
                        var ePointer = pointers.find(p => p.courierRouteID == route.id && p.locationID == custLocation.id);
                        
                        if (ePointer == null) {
                            ePointer = {
                                id: null,
                                isDestination: true,
                                rowVersion: null,
                                pointerName: agr.buyer.companyName,
                                boundary: null,
                                weekdays: 'Always',
                                locationID: custLocation.id,
                                location: custLocation,
                                courierRouteID: route.id
                            };
                        }

                        this.$BlitzIt.tracker.track(ePointer, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { routeName: route.routeName, isLoading: false }
                        });

                        res.push(ePointer);
                    });
                });
            }

            return res;
        },
        async pullSupplyPointers(locations, agr) {
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, false, null, '/Locations/Locations');
            var customerLocations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(customerLocations)) {
                var locationIDs = customerLocations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('supply-pointers', { includeDetails: true, destinationLocationIDs: locationIDs.toString()}, false, null, null);

                locations.forEach(t => {
                    customerLocations.forEach(l => {
                        var p = pointers.find(p => p.destinationLocationID == l.id && p.locationID == t.id);
                        if (p == null) {
                            p = {
                                id: null,
                                rowVersion: null,
                                pointerName: agr.buyer.companyName,
                                boundary: null,
                                weekdays: 'Always',
                                locationID: t.id,
                                location: t,
                                destinationLocationID: l.id,
                            };
                        }

                        this.$BlitzIt.tracker.track(p, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { destinationLocation: l, isLoading: false }
                        });

                        res.push(p);
                    });
                });
            }

            return res;
        },
        async validate(item) {
            try {
                this.loadingMsg = 'Validating Address';
            
                var add = getLocationLine(item, true);
                
                await this.$gmapApiPromiseLazy();
                var google = getGoogleMapsAPI();

                if (this.geocoder == null) {
                    //await googleMapsApiInitializer({ key: process.env.VUE_APP_GOOGLE_KEY }, false);
                    this.geocoder = new google.maps.Geocoder();
                }

                var results = await this.geocoder.geocode({ address: add });

                if (results.results.length > 1) {
                    this.msg = 'Multiple Options Found';
                }
                else if (results.results.length == 1) {
                    item.lat = results.results[0].geometry.location.lat();
                    item.lng = results.results[0].geometry.location.lng();
                    this.repositionMarker(item);
                }
                else {
                    this.msg = 'Address Not Found';
                }
            }
            catch (err) {
                this.msg = 'Failed';
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>